import React, {useState, useEffect, useContext, useRef} from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout";
import Timeline from "../components/timeline";
import SocialButtons from "../components/socialButtons"
import {Link, navigate} from "gatsby";
import {Helmet} from 'react-helmet'


import * as paintingStyles from "./painting.module.css";

import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {myContext} from '../components/PageContext';
import {BiDotsVertical, BiCaretLeft, BiCaretRight} from 'react-icons/bi'
import {AiOutlineRollback} from 'react-icons/ai'


export default function Painting({data, pageContext: {title, key, year, pageCont, medium, size}}) {
    const pageInfo = data.pageInfo.edges.find(item => item.node.id === `SitePage /${title.toLowerCase().replace(/ /g, "-")}`)
    const image = data.painting.localFile
    const originWidth = image.childImageSharp.original.width

    const [clicked, setClicked] = useState(true)

    const context = useContext(myContext);
    const myRef = useRef(null)

    useEffect(() => {
        context.sideNavOff()
    }, [])

    function validatePath(p) {
        if (p) {
            return p.startsWith(year)
        }
        return false
    }

    return (
        <>
            <Helmet htmlAttributes={{lang: 'en-uk'}}>
                <meta charSet="utf-8"/>
                <title>{`Alan Macdonald | ${title}`} </title>
                <meta charSet="utf-8"/>
                <meta name="description" content={`${title} by Alan Macdonald`}/>
                <meta name="keywords"
                      content={`Alan Macdonald, ${image.name}, painting, Scottish, artist, art, Alan, Macdonald`}/>
                <meta name="author" content="Alan Macdonald"/>
                <meta property="og:title" content={title}/>
                <meta property="og:description" content={`${title} by Alan Macdonald`}/>
                <meta property="og:image" content={data.ogimage.localFile.childImageSharp.resize.src}/>

                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="twitter:site" content="@twitter"/>
                <meta property="twitter:title" content="Alan Macdonald"/>
                <meta property="twitter:description" content={title}/>


            </Helmet>
            <Layout current={year}>
                <Timeline current={year}/>
                <main>
                    <myContext.Consumer>
                        {context => (
                            <div>
                                <button onClick={() => context.toggleSideNav()}
                                        style={{zIndex: 2, top: '.75rem', left: '0', position: 'relative'}}
                                        className={"timeline_btn"}><BiDotsVertical/>timeline
                                </button>
                                <div style={{position: 'absolute', top: 'calc(50px - 9px)', left: '1rem'}}>
                                    <button onClick={() => navigate(`/${year}#${pageInfo.node.path}`)}>
                                        <AiOutlineRollback size={18}/></button>
                                </div>
                            </div>

                        )}
                    </myContext.Consumer>
                    <div className={paintingStyles.container}>
                        <div ref={myRef} className={paintingStyles.text}>
                            {pageInfo.previous && validatePath(pageInfo.previous.context.key) ?
                                <Link to={pageInfo.previous.path}><BiCaretLeft size={24}/></Link> :
                                <Link><BiCaretLeft color={"#423e3e"} size={24}/></Link>}
                            {pageInfo.next && validatePath(pageInfo.next.context.key) ?
                                <Link to={pageInfo.next.path}><BiCaretRight size={24}/></Link> :
                                <Link><BiCaretRight color={"#423e3e"} size={24}/></Link>}
                        </div>
                        <div className={paintingStyles.painting}>
                            <GatsbyImage
                                onClick={() => clicked ? setClicked(false) : setClicked(true)}
                                style={{width: originWidth + 'px'}}
                                className={`${clicked ? paintingStyles.zoomedOut : paintingStyles.zoomedIn} ${paintingStyles.image}`}
                                image={getImage(image)}
                                alt={"placeholder"}
                            />
                        </div>
                        <div className={`${paintingStyles.title} ${paintingStyles.title_cont}`}>
                            <h2>{title}</h2>
                            <p>{year}</p>
                            <p>{String(size.split("x").map(i => i + "cm").join(" × "))} / {String(size.split("x").map(i => Math.round((i / 2.54) * 10) / 10 + "\"").join(" × "))}</p>
                            <p>{medium[0].toUpperCase() + medium.substring(1)}</p>
                            <SocialButtons
                                url={typeof window !== 'undefined' && window.location.href}
                                name={title + ' by Alan Macdonald'}
                                bgStyle={{fill: "transparent"}}
                                iconFillColor="var(--main-text-color)"
                                size={42}
                                media={getImage(image)}
                            />
                        </div>
                    </div>
                </main>
            </Layout>
        </>
    );
}
export const query = graphql`
query ($key: String!) {
  pageInfo: allSitePage(
    filter: {context: {key: {ne: null}}}
  ) {
    edges {
      node {
        id
        path
      }
      previous {
        path
        context {
          key
        }
      }
      next {
        path
        context {
          key
        }
      }
    }
  }
  painting: s3Object(Key: {eq: $key}) {
    Key
    localFile {
      relativePath
      childImageSharp {
        original {
          width
        }
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: DOMINANT_COLOR
          formats: [JPG]
        )
      }
    }
  }
  ogimage: s3Object(Key: {eq: $key}) {
    localFile {
      childImageSharp {
        resize(width: 1200) {
          src
          height
          width
        }
      }
    }
  }
}
`;

// paintingsJson( title: { eq: $title } ) {
//   title,
//   filepath,
//   year
// }